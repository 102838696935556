<template>
    <nav
        class="navbar navbar-expand-lg fixed-top navbar-dark bg-dark"
        aria-label="Main navigation"
    >
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/">
                <Logo class="logo" />
            </router-link>
            <button
                class="navbar-toggler p-0 border-0"
                type="button"
                id="navbarSideCollapse"
                aria-label="Toggle navigation"
                @click="toggleMenu"
            >
                <span class="navbar-toggler-icon"></span>
            </button>

            <div
                class="navbar-collapse offcanvas-collapse"
                :class="{ open: showMenu }"
                id="navbarsExampleDefault"
            >
                <Menu/>

                <button class="btn text-white" @click="signout">Sair</button>
            </div>
        </div>
    </nav>
</template>
<script>
import Logo from '../assets/logo.svg'
import Menu from '../components/Menu.vue'
export default {
    components: {
        Logo,
        Menu
    },
    data() {
        return {
            showMenu: false,
        };
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        async signout() {
            await this.$store.dispatch('signOut');
            this.$router.replace('/login');
            location.reload();
        }
    },
};
</script>