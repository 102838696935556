<template>
    <div
        class="
            d-flex
            align-items-center
            p-3
            my-3
            text-white
            bg-purple
            rounded
            shadow-sm
        "
    >
        <Logo class="logo me-4" />
        <div class="lh-1">
            <h1 class="h6 mb-0 text-white lh-1">
                Audita <small>Estoque</small>
            </h1>
            <small>
                Olá
                {{ usuario.nome }}
                &lt;{{ usuario.email }}&gt;
            </small>
        </div>
    </div>
</template>
<script>
import Logo from '../assets/logo.svg'
export default {
    components: {
        Logo
    },
    computed: {
        usuario() {
            return this.$store.getters.usuario;
        },
    }
}
</script>
<style lang="less" scoped>
    svg.logo {
        font-size: 2rem;
        color: white;
    }
</style>