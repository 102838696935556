import { createStore } from 'vuex';


let user = null;


try {
    user = JSON.parse(localStorage.getItem('user'));
} catch (err) {
    user = null;
}


export const store = createStore({
    state() {
        return {
            user,
            usuarios: [],            
            onLine: !!navigator.onLine
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        
        setOnLine(state, online) {
            state.onLine = online;
        },
        setUsuarios(state, usuarios) {
            state.usuarios = usuarios;
        }
    },
    actions: {
        
        setAccessToken({ commit, dispatch }, access_token) {
            if (null === access_token) {
                localStorage.removeItem('access_token');
                return;
            }            
            localStorage.setItem('access_token', access_token);
            dispatch('load');
        },
        setUser({ commit, dispatch }, user) {
            commit('setUser', user);
            if (null === user) {
                localStorage.removeItem('user');
                return;
            }
            localStorage.setItem('user', JSON.stringify(user));
            dispatch('sync');
        },

        signOut({ dispatch }) {
            dispatch('setAccessToken', null);
            dispatch('setUser', null);
            dispatch('setEmpresa', null);            
        },
        sync({ dispatch }) {
            //dispatch('Usuarios/sync');
        },
        load({ dispatch }) {
            
        }
    },
    getters: {
        usuario(state) {
            return state.user;
        }
    },
    modules: {
        
    }
});

window.addEventListener('offline', () => {
    store.commit('setOnLine', false);
});
window.addEventListener('online', () => {
    store.commit('setOnLine', true);
  //  store.dispatch('sync');
});
store.dispatch('load');
if (navigator.onLine) {
    //store.dispatch('sync');
}

export default store;